Lyte.Mixin.register("crux-textautocomplete-options", {//No I18n
	methods : {
		fetchData : function(val, index, field){
			if(field.column_name == "COMPANY"){
				if(!store.modelFor(moduleRecordMapping.Accounts.id)){
					store.registerModel(moduleRecordMapping.Accounts.id, {}, {extends : "entity"});//No I18n
					this.accountFieldsFetched = false;
				}
				var filters = {comparator : "contains", field : {api_name : "Account_Name"}, value : val};//No I18n
				var promises = [store.findAll(moduleRecordMapping.Accounts.id, {filters : filters, page : index, per_page : 10, sort_by : "Account_Name", sort_order : "asc",//No I18n
					approved : "both"}, false, false)];//No I18n
				if(!this.accountFieldsFetched){
					promises.push(store.findRecord("module", moduleRecordMapping.Accounts.id));
					this.accountFieldsFetched = true;
				}
				return Lyte.resolvePromises(promises).then(function(response){
					return response[0][moduleRecordMapping.Accounts.id];
				})
			}
			else if(field.column_name == "COUNTRY" || field.column_name == "SHIPPINGCOUNTRY" || field.column_name == "MAILINGCOUNTRY" || field.column_name == "BILLINGCOUNTRY" || field.column_name == "OTHERCOUNTRY"){
				return I18n.properties.COUNTRY_LIST2;
			}
		}
	},
	getTextAutocompleteOptions : function(field){
		var obj = {enable : true};
		if(field.column_name == "COMPANY"){
			obj.yield = true;
			obj.externalSearch = true;
			obj.minLength = 3;
			obj.displayField = "Account_Name";//No I18n
			obj.module = "Accounts";//No I18n
			obj.idField = "id";//No I18n
		}
		else if(field.column_name == "COUNTRY"){
			obj.minLength = 2;
			obj.method = "startsWith";//No I18n
			obj.content = this.executeMethod("fetchData",undefined, undefined, field);//No I18n
		}
		// else if(field.column_name == "SUBJECT"){
		// 	obj.minLength = 0;
		// }
		else{
			obj.enable = false;
		}
		return obj;
	}
});

